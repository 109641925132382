import { AuthSettings, useExecuteAuthenticationFlow } from '@features/auth';
import { ErrorPage, HomePage, ProjectPage } from '@pages/lazyLoadingPages';
import { Loader } from '@shared/ui';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteGuard } from './RouteGuard';

type Props = {
    authSettings: AuthSettings;
    uploadAcceptedFormats: string;
};

const ApplicationRoutes = ({ authSettings, uploadAcceptedFormats }: Props) => {
    const { isLoadingAuthentication } = useExecuteAuthenticationFlow();

    return (
        <Routes>
            <Route
                element={
                    <Suspense fallback={<Loader size="fullScreen" />}>
                        <RouteGuard scopes={authSettings.scopes} isLoadingAuthentication={isLoadingAuthentication} />
                    </Suspense>
                }
            >
                <Route index element={<HomePage />} />
                <Route
                    element={<ProjectPage origin="dataDump" uploadAcceptedFormats={uploadAcceptedFormats} />}
                    path="/project/datadump/:projectId"
                />
                <Route element={<ProjectPage origin="create" />} path="/project/scope/create" />
                <Route element={<ProjectPage origin="update" />} path="/project/scope/update/:projectId" />
                <Route element={<ProjectPage origin="generating_persona" />} path="/project/persona/:projectId" />
                <Route element={<ProjectPage origin="chat" />} path="/project/chat/:projectId" />
                <Route element={<ProjectPage origin="list" />} path="/myprojects" />
                <Route element={<ErrorPage />} path="/error" />
            </Route>
        </Routes>
    );
};

export { ApplicationRoutes };
