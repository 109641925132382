import classNames from 'classnames';
import { ReactElement } from 'react';
import './button.scss';
import { Tooltip } from 'react-tooltip';

type Props = {
    onClick: () => void;
    text?: string;
    icon?: ReactElement;
    iconPlacement?: 'left' | 'right';
    size?: 'xl' | 'l' | 'm' | 's';
    category?: 'primary' | 'secondary' | 'tertiary' | 'error';
    isDisabled?: boolean;
    testId?: string;
    tooltip?: {
        id: string;
        placement: 'top' | 'right' | 'bottom' | 'left';
        content: ReactElement;
    };
};

const Button = ({
    onClick,
    text,
    icon,
    iconPlacement = 'left',
    size = 'm',
    category = 'primary',
    isDisabled = false,
    testId,
    tooltip,
}: Props) => {
    const buttonClass = classNames('button', {
        [`button--${size}`]: size,
        [`button--${category}`]: category,
        [`button__icon--${iconPlacement}`]: icon && iconPlacement,
        'button__icon--only': icon && !text,
    });

    return (
        <>
            <button
                type="button"
                onClick={onClick}
                className={buttonClass}
                disabled={isDisabled}
                data-testid={testId}
                {...(tooltip ? { 'data-tooltip-id': tooltip.id } : null)}
            >
                {icon && iconPlacement === 'left' ? icon : null}
                {text ? <span>{text}</span> : null}
                {icon && iconPlacement === 'right' ? icon : null}
            </button>
            {tooltip ? (
                <Tooltip anchorSelect={`[data-tooltip-id=${tooltip.id}]`} place={tooltip.placement}>
                    {tooltip.content}
                </Tooltip>
            ) : null}
        </>
    );
};

export { Button };
